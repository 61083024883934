import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.trackingNavigationOnGoogleAnalytics();
  }

  trackingNavigationOnGoogleAnalytics = () => {
    const navigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    navigationEnd.subscribe((event: NavigationEnd) => {
      if (environment.production) {
        gtag('config', 'G-FMF6PJ7R5P', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  };

}
