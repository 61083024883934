import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutWebComponent } from './layout-web.component';
import { MaterialModule } from '../../../core/material.module';
import { RouterModule } from '@angular/router';
import { HeaderWebComponent } from './components/header-web/header-web.component';
import { SidenavWebComponent } from './components/sidenav-web/sidenav-web.component';
import { FooterWebComponent } from './components/footer-web/footer-web.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    LayoutWebComponent,
    HeaderWebComponent,
    SidenavWebComponent,
    FooterWebComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FontAwesomeModule
  ]
})
export class LayoutWebModule { }
