import { Component, Input } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatSidenav } from '@angular/material/sidenav';
import { Link } from '../../shared/models/link';
import { SocialNetwork } from '../../shared/models/social-network';
import { links, headerSocialNetworks } from '../../shared/constants';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-sidenav-web',
  templateUrl: './sidenav-web.component.html',
  styleUrls: ['./sidenav-web.component.scss']
})
export class SidenavWebComponent {

  @Input() sidenavElement: MatSidenav;
  @Input() isMobile: boolean;

  links: Link[] = links;
  socialNetworks: SocialNetwork[] = headerSocialNetworks;

  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(faFacebook, faInstagram);
  }

  closeMenu(): void {
    this.sidenavElement.close();
  }

}
