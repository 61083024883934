import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent {

  @Input() sidenavElement: MatSidenav;

  constructor(
    private library: FaIconLibrary,
    private router: Router,
    private userService: UserService
  ) {
    this.library.addIcons(faBars, faPowerOff);
  }

  closeMenu(): void {
    this.sidenavElement.close();
  }

  logout(): void {
    this.userService.doLogout();
  }

}
