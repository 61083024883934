<app-header-admin [sidenavElement]="sidenav"></app-header-admin>

<mat-sidenav-container class="container">
    <mat-sidenav #sidenav data-cy="sidenav" class="sidenav" [mode]="isDesktopScreen ? 'side' : 'over'"
        fixedInViewport="true" [opened]="isDesktopScreen">
        <app-sidenav-admin [sidenavElement]="sidenav" [isDesktopScreen]="isDesktopScreen"></app-sidenav-admin>
    </mat-sidenav>

    <mat-sidenav-content class="main-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>