import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { ScreenService } from 'src/app/services/screen.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-layout-web',
  templateUrl: './layout-web.component.html',
  styleUrls: ['./layout-web.component.scss']
})
export class LayoutWebComponent implements AfterViewInit {

  @ViewChild('sidenavMenu') sidenavMenu: MatSidenav;

  isMobileScreenSubscription: Subscription;
  isMobile: boolean;

  constructor(
    private screenService: ScreenService
  ) { }

  ngAfterViewInit(): void {
    this.isMobileScreenSubscription = this.screenService.isMobileScreen()?.pipe(delay(0)).subscribe((isMobileScreen) => {
      this.isMobile = isMobileScreen;
      this.sidenavMenu.close();
    });
  }

}
