import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { Token } from '../models/token';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  URL: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) { }

  doLogin(email: string, password: string): Observable<Token> {
    return this.http.post<Token>(`${this.URL}/backoffice/users/login`, { email, password });
  }

  isLogged(): boolean {
    return this.storageService.getToken().token != null;
  }

  doLogout(): void {
    this.storageService.clear();
    this.router.navigate(['login']);
  }

}
