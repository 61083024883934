<header class="header">
  <span class="material-icons icon" *ngIf="isMobile && sidenavElement.opened" (click)="closeMenu()">close</span>
  <span class="material-icons icon" *ngIf="isMobile && !sidenavElement.opened" (click)="openMenu()">menu</span>

  <a class="header__logo" routerLink="/home">
    <img class="header__logo-icon" src="assets/icons/4sinfondo.svg">
  </a>

  <nav *ngIf="!isMobile">
    <ul class="menu-list">
      <li class="menu-item" *ngFor="let link of links">
        <button class="menu-item-button" mat-button [routerLink]="link.url"
          routerLinkActive="menu-item-active">{{link.text}}</button>
      </li>
    </ul>
  </nav>

  <ul *ngIf="!isMobile" class="social-network-list">
    <li class="social-network-item" *ngFor="let socialNetwork of socialNetworks">
      <a mat-icon-button [attr.data-cy]="socialNetwork.id" [href]="socialNetwork.url" target="_blank"
        rel="noopener noreferrer" class="link-icon" [attr.aria-label]="socialNetwork.text">
        <fa-icon [icon]="[socialNetwork.iconPrefix, socialNetwork.icon]" [size]="socialNetwork.size"></fa-icon>
      </a>
    </li>
  </ul>
</header>