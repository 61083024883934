import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { SocialNetwork } from '../../shared/models/social-network';
import { Link } from '../../shared/models/link';
import { headerSocialNetworks, links } from '../../shared/constants';

@Component({
  selector: 'app-header-web',
  templateUrl: './header-web.component.html',
  styleUrls: ['./header-web.component.scss']
})
export class HeaderWebComponent {

  @Input() sidenavElement: MatSidenav;
  @Input() isMobile: boolean;

  links: Link[] = links;
  socialNetworks: SocialNetwork[] = headerSocialNetworks;

  constructor(
    private library: FaIconLibrary
  ) {
    this.library.addIcons(faFacebook, faInstagram, faBars);
  }

  openMenu(): void {
    this.sidenavElement.open();
  }

  closeMenu(): void {
    this.sidenavElement.close();
  }

}
