import { Component, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.scss']
})
export class SidenavAdminComponent {

  @Input() sidenavElement: MatSidenav;
  @Input() isDesktopScreen: boolean;

  links = [
    { text: 'Dashboard', url: '/admin/dashboard' },
    { text: 'Portfolio', url: '/admin/portfolio' },
  ];

  constructor() { }

  closeIfIsMobile(): void {
    if (!this.isDesktopScreen) {
      this.sidenavElement.close();
    }
  }

}
