import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.redirectDependingIfIsLogged(state);

    return true;
  }

  private redirectDependingIfIsLogged(state: RouterStateSnapshot): void {
    const isLogged = this.userService.isLogged();

    this.goToLoginIfUserNotLogged(isLogged, state);
    this.goToHomeIfUserIsLogged(isLogged, state);
  }

  private goToLoginIfUserNotLogged(isLogged: boolean, state: RouterStateSnapshot): void {
    if (!isLogged && state.url !== '/login') {
      this.router.navigate(['/login']);
    }
  }

  private goToHomeIfUserIsLogged(isLogged: boolean, state: RouterStateSnapshot): void {
    if (isLogged && state.url === '/login') {
      this.router.navigate(['/admin/dashboard']);
    }
  }
}

