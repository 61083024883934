<header>
  <mat-toolbar class="header-toolbar">
    <div class="header-menu">
      <button mat-icon-button data-cy="headerToggleSidenav" (click)="sidenavElement.toggle()" aria-label="menu">
        <fa-icon class="icon" [icon]="['fas', 'bars']" size="lg"></fa-icon>
      </button>
      <a class="header-item-name" data-cy="headerName" routerLink="/admin/dashboard">
        <h1>Administración</h1>
      </a>
    </div>
    <fa-icon (click)="logout()" class="icon" [icon]="['fas', 'power-off']" size="lg"></fa-icon>
  </mat-toolbar>
</header>