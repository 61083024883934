<app-header-web [sidenavElement]="sidenavMenu" [isMobile]="isMobile"></app-header-web>

<mat-sidenav-container class="sidenav-container" hasBackdrop="true">
    <mat-sidenav class="sidenav" #sidenavMenu mode="over" disableClose="true" autoFocus="false" fixedInViewport="true"
        opened="false" position="start">
        <app-sidenav-web [sidenavElement]="sidenavMenu" [isMobile]="isMobile"></app-sidenav-web>
    </mat-sidenav>

    <mat-sidenav-content class="container">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>