import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './material.module';
import { LayoutWebModule } from '../contexts/web/layout-web/layout-web.module';
import { LayoutAdminModule } from '../contexts/admin/layout-admin/layout-admin.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    MaterialModule,
    FontAwesomeModule,
    HttpClientModule,
    LayoutWebModule,
    LayoutAdminModule
  ]
})
export class CoreModule { }
