<div class="sidenav-container">
  <section class="sidenav-content">
    <nav class="sidenav-content-nav">
      <mat-button-toggle-group data-cy="sidenavMenu" class="menu-list" vertical="true">
        <mat-button-toggle data-cy="sidenavMenuElement" *ngFor="let link of links" class="menu-item" mat-button [routerLink]="link.url"
          routerLinkActive="mat-button-toggle-checked" (click)="closeIfIsMobile()">
          {{link.text}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </nav>
  </section>

  <section class="sidenav-footer" data-cy="sidenavFooter">
    <h1 class="sidenav-footer-name">Iván Córdoba</h1>
    <h2 class="sidenav-footer-tagline">Software Developer</h2>
  </section>
</div>