<div class="container">
  <nav>
    <ul class="menu-list">
      <li class="menu-item" *ngFor="let link of links">
        <button mat-button class="menu-item-button" [routerLink]="link.url" (click)="closeMenu()" routerLinkActive="menu-item-active">{{link.text}}</button>
      </li>
    </ul>
  </nav>

  <ul class="social-network-list">
    <li *ngFor="let socialNetwork of socialNetworks">
      <a mat-icon-button [attr.data-cy]="socialNetwork.id" [href]="socialNetwork.url" target="_blank" rel="noopener noreferrer" class="link-icon" [attr.aria-label]="socialNetwork.text">
        <fa-icon [icon]="[socialNetwork.iconPrefix, socialNetwork.icon]" [size]="socialNetwork.size"></fa-icon>
      </a>
    </li>
  </ul>
</div>