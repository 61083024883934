import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { BREAKPOITNS } from '../core/constants';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  isDesktopScreen = (): Observable<boolean> => {
    if (isPlatformBrowser(this.platformId)) {
      return new Observable((observer) => {
        observer.next(this.checkIsDesktopScreen());
        window.onresize = () => observer.next(this.checkIsDesktopScreen());
      });
    }
  }

  isTabletScreen = (): Observable<boolean> => {
    if (isPlatformBrowser(this.platformId)) {
      return new Observable((observer) => {
        observer.next(this.checkIsTabletScreen());
        window.onresize = () => observer.next(this.checkIsTabletScreen());
      });
    }
  }

  isMobileScreen = (): Observable<boolean> => {
    if (isPlatformBrowser(this.platformId)) {
      return new Observable((observer) => {
        observer.next(this.checkIsMobileScreen());
        window.onresize = () => {
          observer.next(this.checkIsMobileScreen());
        };
      });
    }
  }

  private checkIsDesktopScreen(): boolean {
    return window.outerWidth >= BREAKPOITNS.DESKTOP;
  }

  private checkIsTabletScreen(): boolean {
    return window.outerWidth >= BREAKPOITNS.TABLET && window.outerWidth < BREAKPOITNS.DESKTOP;
  }

  private checkIsMobileScreen(): boolean {
    return window.outerWidth < BREAKPOITNS.TABLET;
  }

}
