
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, NavigationEnd, Event } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { ScreenService } from 'src/app/services/screen.service';

@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.scss']
})
export class LayoutAdminComponent implements OnInit {

  isDesktopScreen: boolean;

  navigationEnd: Observable<Event>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private screenService: ScreenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.checkScreenSize();
    this.navigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.goTopWhenComponentChange();
  }

  checkScreenSize = () => {
    this.screenService.isDesktopScreen().subscribe((isDesktopScreen: boolean) => {
      this.isDesktopScreen = isDesktopScreen;
    });
  }

  goTopWhenComponentChange = () => {
    this.navigationEnd.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    });
  }

}
