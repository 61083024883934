import { SocialNetwork } from './models/social-network';
import { Link } from './models/link';

export const links: Link[] = [
  { text: 'INICIO', url: '/home' },
  { text: 'PORTFOLIO', url: '/portfolio' },
  { text: 'PROYECTOS', url: '/projects' },
  { text: 'SOBRE MÍ', url: '/about-me' },
  { text: 'CONTACTO', url: '/contact' },
];

export const headerSocialNetworks: SocialNetwork[] = [
  // {
  //     id: 'headerSocialNetworkIconFacebook',
  //     url: 'https://www.facebook.com/LauraCordobaPhotographs/',
  //     iconPrefix: 'fab',
  //     icon: 'facebook',
  //     size: '2x',
  //     color: 'white',
  //     text: 'facebook',
  // },
  {
    id: 'headerSocialNetworkIconInstagram',
    url: 'https://www.instagram.com/lauracordoba.es',
    iconPrefix: 'fab',
    icon: 'instagram',
    size: '2x',
    color: 'white',
    text: 'instagram'
  }
];

