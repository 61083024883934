import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LayoutWebComponent } from './contexts/web/layout-web/layout-web.component';
import { LayoutAdminComponent } from './contexts/admin/layout-admin/layout-admin.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./contexts/admin/pages/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./contexts/admin/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./contexts/admin/pages/portfolio/portfolio.module').then(m => m.PortfolioModule),
      },
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: '/login'
      },
    ]
  },
  {
    path: '',
    component: LayoutWebComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./contexts/web/pages/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./contexts/web/pages/portfolio/portfolio.module').then(m => m.PortfolioModule),
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'projects',
        loadChildren: () => import('./contexts/web/pages/projects/projects.module').then(m => m.ProjectsModule),
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'about-me',
        loadChildren: () => import('./contexts/web/pages/about-me/about-me.module').then(m => m.AboutMeModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./contexts/web/pages/contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
