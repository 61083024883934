import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutAdminComponent } from './layout-admin.component';
import { MaterialModule } from '../../../core/material.module';
import { RouterModule } from '@angular/router';
import { HeaderAdminComponent } from './components/header-admin/header-admin.component';
import { SidenavAdminComponent } from './components/sidenav-admin/sidenav-admin.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../../../core/interceptors/token.interceptor';



@NgModule({
  declarations: [
    LayoutAdminComponent,
    HeaderAdminComponent,
    SidenavAdminComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    }
  ],
})
export class LayoutAdminModule { }
