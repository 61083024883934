import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-web',
  templateUrl: './footer-web.component.html',
  styleUrls: ['./footer-web.component.scss']
})
export class FooterWebComponent {

  currentYear = (new Date()).getFullYear();

  constructor() { }

}
