import { Injectable } from '@angular/core';
import { Token } from '../models/token';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveToken(token: Token): void {
    localStorage.setItem('token', token.token);
  }

  getToken(): Token {
    const token: Token = { token: '' };
    token.token = localStorage.getItem('token');
    return token;
  }

  clear(): void {
    localStorage.clear();
  }

}
